import React from "react";
import Hero from "../../Components/Hero/Hero.jsx";
import "./Home.css";
function Home() {
  return (
    <div className="Heropage h-screen">
      <Hero />
    </div>
  );
}

export default Home;
